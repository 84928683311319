@media (max-width: 1200px) {

    .line {
        width: 80%;
    }
}

@media (max-width: 1024px) {
    .margin-btn {
        margin-left: 24px;
    }

    .margin-links {
        margin-right: 20px;
    }
}


    @media (max-width: 960px) {
    .main-contact,
    .main-team {
        margin-top: 87px;
    }

    .main-conteiner,
    .service-conteiner  {
        padding-top: 87px;
    }

    .clients-interior-wrapper {
        margin-top: 121px;
    }

    .container-main {   
        margin: 0px auto;
    }

    .clients-conteiner {
        height: calc(100vh - 87px);
    }

    .wrap-team--reverse, .wrap-team {
        flex-direction: column;
        width: 100%;
        padding-left: 30px;
        padding-right: 30px;
    }

    .team-wrap--two {
        width: 100%; 
        animation-duration: 0s; 
        display: block;
    }

    .team-wrap--one {
        margin-right: 0; 
        padding-right: 0px; 
        padding-left: 0px;
    }

    .wrap-team--reverse {
        flex-direction: column;
    }
    .margin-img {
        margin-right: 0; 
        margin-left: 0; 
        padding-left: 0; 
        padding-right: 0; 
    }


// Header burger menu
.header {
    display: flex;
    flex-direction: column-reverse;
    height: 87px;
}

.header-wrap {
    display: none;
}

.header-menu_open {
    width: 32px;
    content: url(./../../image/menu.png);
    display: block;
}

.header-menu_close {
    width: 32px;
    content: url(./../../image/close.png);
    display: block;
}

.header-burger {
    margin-top: 87px;
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: #ffff;
    z-index: 9999;
}

.burger-wrap {
    padding-left: 47px;
    display: flex;
    flex-direction: column;
    margin-top: 51px;
}

.margin-links {
    margin-right: 0px;
    margin-bottom: 30px;
}

.header-phone_wrap {
    justify-content: space-between;
}

.header-logo {
    margin-bottom: 0px;
    width: 108px;
}

.header-container {
    padding: 19px 16px 22px 16px;
}
.header-phone_wrap {
    padding: 0px 16px 6px 16px;
}
.header-phone_text {
    font-size: 14px;
    font-weight: bold;
}
.margin {
    margin-right: 0px;
}
}


@media (max-width: 400px) {
    .header-logo {
        width: 108px;
    }
}

@media (max-width: 800px) {
    .team-wrap--two {
        font-size: 22px;
    }
}

@media (max-width: 700px) {
    .team-wrap--two {
        font-size: 20px;
    }
}

@media (max-width: 600px) {
    .team-wrap--two {
        font-size: 18px;
    }
}

@media (max-width: 500px) {
    .header-logo {
        width: 108px;
    }
    .team-wrap--two {
        font-size: 14px;
    }
    .team-text_name {
        text-align: center;
        font-size: 14px;
        margin-bottom: 22px;
    }
    .team-text_post {
        font-size: 9px;
        margin-bottom: 5px; 
    }

    .container-main {
         margin: 0px auto;
    }
    .team-wrap--one {
        height: 100%;
    }
    .team-img {
        margin-top: 22px;
        margin-bottom: 22px;
        width: 128px;
        height: 132px;
    }
    .line {
        margin-bottom: 10px;
    }
}

//footer
