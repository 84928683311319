.header {
    width: 100%;
    height: 140px;
    position: fixed;
    top: 0;
    background-color: #ffff;
    box-shadow: 0 0 10px rgba(0,0,0,.1);
    z-index: 9999;
}   

.header-phone_wrap {
    max-width: 1366px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 14px 30px 0px 32px;
    margin: auto;
}

.margin {
    margin-right: 23px;
}

.header-phone_text {
    color: black;
    font-size: 18px;
}   

.header-container {
    max-width: 1366px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 19px 32px 22px 64px;
    margin: auto;
}

.header-wrap_link-btn,
.header-wrap_button, 
.header-wrap_link {
    font-family: 'Open Sans', bold;
    font-weight: bold;
    font-size: 20px;
    text-decoration: none;
    color: black;
}

.header-wrap_button {
    width: 149px;
    height: 56px;
    opacity: 1;
    background: none;
    border: 1px solid #000000;
}

.header-wrap {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-wrap_link-btn:hover,
.header-wrap_link:hover {
color: black;
}

.margin-links {
    margin-right: 36px;
}

.margin-btn {
    margin-left: 47px ;
}

.header-logo {
    width: 179px;
    content: url(./../../image/logo2.png);
    margin-bottom: 13px;
}

.border {
    border-bottom: 1px solid black;
}

.header-menu_open {
    width: 32px;
    content: url(./../../image/menu.png);
    display: none;
}

.header-menu_close {
    width: 32px;
    content: url(./../../image/close.png);
    display: none;
}
