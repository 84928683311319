@mixin font-Merriweather-regular {
    font-family: 'Merriweather', serif;
    font-weight: 400;
} 

@mixin font-Merriweather-bold {
    font-family: 'Merriweather', serif;
    font-weight: 700;
}

@mixin font-Open_sans-regular {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
}

@mixin font-Open_sans-bold {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
}


.review-conteiner {
    display: flex;
    align-items: center;
    min-height: 100vh;
    padding-top: 140px;
    width: 100%;
}

.review-conteiner-wrapper {
    max-width: 1366px;
    width: 90%;
    margin: 0 auto;
}

.slider-arrow-next {
    width: 40px;
    height: 80px;
    transform: rotate(180deg);
    left: -20px;
position: absolute;
}


.slick-next, .slick-prev {
    width: 0px !important;
    height: 0px !important;
}

.slick-next:before, .slick-prev:before {
    content: '' !important;
    font-size: 0px !important;
}

.slider-arrow-prev {
    width: 40px;
    height: 80px;
}

.slick-slide > div {
    text-align: center;
}

.review-commentator-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 54px;
    padding-top: 108px;
}

.review-commentator-info > img {
    width: 300px;
    height: 300px;
    margin-bottom: 54px;
    margin-right: 0px;
}

.review-blocks {
    width: 100%;
    display: inline-flex !important;
    justify-content: space-between;
    max-width: 1146px;
    padding-top: 40px;
    padding-bottom: 10px;
    padding-left: 25px;
    padding-right: 25px;
}

.review-commentator-info >:nth-child(2) {
    @include font-Merriweather-bold;
    font-size: 28px;
    text-align: left;
    letter-spacing: 0;
    color: #000000;
    opacity: 1;
    margin-bottom: 14px;
}

.review-commentator-info >:nth-child(3) {
    @include font-Merriweather-bold;
    font-size: 24px;
    text-align: left;
    color: #000000;
    opacity: 1;
    margin-bottom: 13px;
}

.review-comment-text {
     overflow: hidden;
    @include font-Open_sans-regular;
    max-width: 790px;
    width: 100%;
    height: 600px;
    display: inline-flex;
    align-items: center;
    text-align: left;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 5px 10px 20px #00000029;
    border-radius: 76px;
    opacity: 0.9;
    opacity: 1;
    padding: 16px 50px;
    text-indent: 35px;
    color: #000000;
    
    font-size: 20px;
}

.slick-prev {
    left: -45px !important;
}


// MOBILE VERSION

@media (max-width: 568px) {

    .review-conteiner {
        margin: 0;
        margin-bottom: 14px;
    }

    .review-conteiner-wrapper {
       text-align: center;
    }
       
    .review-commentator-info > img {
        width: 120px;
        height: 120px;
        margin-bottom: 18px;
        margin-right: 0PX;
    }

    .review-commentator-info > :nth-child(2) {
        font-size: 16px;
    }

    .review-commentator-info > :nth-child(3) {
        font-size: 14px;
        margin-bottom: 13px;
    }

    .review-comment-text {
        font-size: 14px;
        height: min-content;
        width: min-content;
        box-shadow: none;
        border-radius: 0px;
        padding: 0px;
        margin: 0 !important;
        width: auto;
    }

    .review-blocks {
        display: block !important;
    }

}

@media (max-width: 768px) {
    .review-commentator-info > img {
        margin-right: 0px !important;
    }

    .review-comment-text {
        display: flex;
        align-items: baseline;
        width: auto;
        margin-left: 0px;
        box-shadow: none;
        border-radius: 0px;
    }

}

@media (max-width: 960px) {

    .review-conteiner {
        padding-top: 87px;
    }

    .review-blocks {
        display: block !important;
    }

   .slick-arrow, .slick-prev {
    display: none !important;
}

    .slick-arrow, .slick-next {
    display: none !important;
}
}

@media (max-width: 1024px) {
    .review-blocks {
        padding: 34px 30px 14px 30px;
    }

   .review-comment-text {
    height: auto;
   }
}
       



