
.clients-conteiner {
    max-width: 1366px;
    margin: 0 auto;
    height: calc(100vh - 140px);
    display: flex;
    min-height: -webkit-fill-available;
    align-items: center;
    min-height: 100vh;
}

.clients-interior-wrapper > .clients-image:first-child {
    margin-bottom: 75px;
}

.clients-interior-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 140px;
}

.clients-conteiner-image {
    width: 200px;
    margin-right: 20px;
    height: 200px;
}

.clients-image {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    height: min-content;
}

@media (max-width: 568px) {
    
    .clients-conteiner {
        height: 100vh;
        display: block;
        min-height: inherit;
        align-items: center;
    }
    
    .clients-interior-wrapper > .clients-image:first-child {
        margin-bottom: 0px;
    }
    
    .clients-interior-wrapper {
       
        flex-direction: row;
        flex-wrap: initial;
        display: flex;
    }

    .clients-image {
        flex-direction: column;
        width: 100%;
    }

    .clients-conteiner-image {
        width: 100px;
        height: 100px;
        margin-right: 0px;
    }
    
}

@media (max-width: 900px) {
    .clients {
        justify-content: center;
    }
    .clients-conteiner-image {
        width: 100px;
        height: 100px;
        margin-bottom: 25px;
    }
}
