.main-contact {
    width: 100%;
    height: 100%;
    margin-top: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-container {
    max-width: 1366px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container-google {
    margin-right: 130px;

}

.wrap-text_h1 {
    font-size: 24px;
    font-family: 'Merriweather', bold;
    font-weight: bold;
    color: #000000;
}

.wrap-text_p{
    font-size: 20px;
    font-family: 'Open Sans', Regular;
    font-weight: 400;
    color: #000000;
}

.container-google {
    width: 100%;
    height: 100%;
    text-align: center;
}
.iframe {
    width: 450px;
    height: 450px;
}

.margin22 {
    margin-bottom: 22px;
}

.margin24 {
    margin-bottom: 24px;
}

.margin38 {
    margin-bottom: 38px;
}

.margin27 {
    margin-bottom: 27px;
}

.margin31 {
    margin-bottom: 31px;
}

.margin34 {
    margin-bottom: 34px;
}