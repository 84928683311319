@mixin font-Open_sans-regular {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
}

@mixin font-Open_sans-bold {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
}

@mixin font-Merriweather-bold {
    font-family: 'Merriweather', serif;
    font-weight: 700;
}

@mixin font-Merriweather-regular {
    font-family: 'Merriweather', serif;
    font-weight: 400;
}

$color-fff:#FFFFFF;

.main {
    min-height: 100vh;
    width: 100%;
}

.main-conteiner {
    width: 100%;
    min-height: 100vh;
    padding-top: 140px;
}

.main-wrapper {
    max-width: 1366px;
    margin: 0 auto;
    min-height: 100vh;
}

.main-header {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url(../../image/shutterstock_280366622.png);
    max-width: 1366px;
    width: 100%;
    height: 628px;
    background-position: center;
}

.main-header::before {
    content: '';
    position:absolute;
    max-width: 1366px;
    width: 100%;
    height: 628px;
    background: rgba(0,0,0,0.5);
}

.main-header >p:first-child {
    @include font-Merriweather-bold;
    color: $color-fff;
    font-size: 48px;
    opacity: 1;
    z-index: 10;
    margin-bottom: 28px;
}

.main-header >:nth-child(2) {
    @include font-Merriweather-regular;
    color: $color-fff;
    font-size: 32px;
    opacity: 1;
    z-index: 10;
    margin-bottom: 28px;
}

.button-our-contacts {
    z-index: 10;
    width: 100%;
    max-width: 339px;
    height: 78px;
    background: #000000 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 39px;
    opacity: 1;
    border: none;
    text-align: center;
    @include font-Open_sans-regular;
    font-size: 24px;
    cursor: pointer;
}

.button-our-contacts > a {
    color: $color-fff;
}

.button-our-contacts >a:hover {
    color: $color-fff;
}

.main-content-history-auditing {
    margin-top: 157px;
    margin-right: 97px;
    margin-bottom: 84px;
    margin-left: 67px;
    display: inline-flex;
}

.history-auditing-text {
    margin-left: 69px;
    margin-bottom: 19px;
    margin-top: 29px;
}

.history-auditing-text >p:first-child {
    @include font-Merriweather-bold;
    text-align: center;
    margin-bottom: 36px;
    font-size: 36px;
    color: #000000;
    opacity: 1;
}

.history-auditing-text >p:last-child {
    text-align: left;
    @include font-Open_sans-regular;
    font-size: 24px;
    color: #000000;
    opacity: 1;
    margin-bottom: 0px;
}
.history-auditing-text {
    text-align: left;
    @include font-Open_sans-regular;
    font-size: 24px;
    color: #000000;
    opacity: 1;
    margin-bottom: 0px;

}

.main-content-choice>p:first-child {
    @include font-Merriweather-bold;
    text-align: center;
    font-size: 36px;
    color: #000000;
    opacity: 1;
    margin-bottom: 97px;
}

.main-content-choice-wrapper {
    display: inline-flex;
    padding-left: 32px;
    padding-right: 32px;
}

.main-content-choice-text {
    width: 100%;
}

.main-content-choice-image {
    margin-left: 79px;
}

.main-content-choice_blocks >p {
    width: 100%;
    margin-top: 36px;
    margin-bottom: 24px;
    text-align: left;
}

.main-content-choice_blocks >p:first-child {
    width: 100%;
    @include font-Open_sans-bold;
    text-align: left;
    font-size: 24px;
    color: #000000;
    margin-top: 0px;
    margin-bottom: 24px;
}

.main-content-choice_blocks >p:last-child {
    width: 100%;
    text-align: left;
    @include font-Open_sans-regular;
    text-align: left;
    font-size: 24px;
    color: #000000;
    margin-bottom: 18px;
}

.main-content-doc{
    width: 100%;
    font-family: 'Merriweather', serif;
    font-weight: 700;
    text-align: center;
    font-size: 36px;
    color: #000000;
    margin-top: 50px;
    margin-bottom: 30px;
 
    .main-content-doc-img {
        width: 100%;   
    }
}

// MOBILE VERSION

@media (max-width: 568px) {
    .main-content {
        text-align: center;
    }

    .main-header {
        width: auto;
        text-align: center;
    }

    .main-header::before {
        width: -webkit-fill-available;;
    }

    .main-header >p:first-child {
        font-size: 24px;
        margin-bottom: 7px;
    }

    .main-header >:nth-child(2){
        font-size: 16px;
    }

    .button-our-contacts {
        max-width: 174px;
        height: 49px;
        font-size: 16px;
    }

    .history-auditing-text > p:first-child {
        font-size: 16px;
        margin: 0px 0px 37px 0px;
        //display: none;
    }
    .history-auditing-text > p:last-child {
        margin-bottom: 24px;
        text-align: left;
        font-size: 12px;
    }

    .main-content-history-auditing {
         margin: 29px 0px 0px 0px;
    }

    .main-content-choice-wrapper {
        display: inline-flex;
        flex-direction: column-reverse;
        align-items: center;
        padding-left: 32px;
        padding-right: 32px;
    }

    .main-content-choice-text {
        width: 100%;
    }

    .main-content-choice > p:first-child {
        font-size: 16px !important;
        margin-bottom: 0px;
    }

    .main-content-choice-image {
        margin-left: 0px;
    }

    .history-auditing-image {
        width: 200px;
        height: 170px;
    }

    .main-content-choice-image {
        display: none;
    }

    .main-content-choice_blocks >p {
        width: 100%;
        font-size: 12px !important;
        text-align: left;
        margin-bottom: 24px;
    }
    
}

@media (max-width: 640px) {
    .main-content-choice > p:first-child {
        font-size: 16px;
        margin-bottom: 37px;
    }
    .main-content {
        text-align: center;
    }

    .main-header {
        width: -webkit-fill-available;
        text-align: center;
    }

    .main-header::before {
        width: -webkit-fill-available;;
    }

    .main-header >p:first-child {
        font-size: 24px;
        margin-bottom: 7px;
    }

    .main-header >:nth-child(2){
        font-size: 16px;
    }

    .button-our-contacts {
        max-width: 174px;
        height: 49px;
        font-size: 16px;
    }

    .history-auditing-text > p:first-child {
        font-size: 16px;
        margin: 0px 0px 37px 0px;
        //display: none;
    }
    .history-auditing-text > p:last-child {
        margin-bottom: 18px;
        text-align: left;
        font-size: 12px;
    }

    .main-content-history-auditing {
         margin: 29px 0px 0px 0px;
    }

    .main-content-choice-wrapper {
        display: inline-flex;
        flex-direction: column-reverse;
        align-items: center;
        padding-left: 32px;
        padding-right: 32px;
    }

    .main-content-choice-text {
        width: 100%;
    }

    .main-content-choice>p:first-child {
        font-size: 16px;
        margin: 0px 0px 37px 0px;
    }

    .main-content-choice-image {
        margin-left: 0px;
    }

    .history-auditing-image {
        width: 200px;
        height: 170px;

    }

    .main-content-choice-image {
        display: none;
    }

    .main-content-choice_blocks > p:first-child {
        width: 100%;
        text-align: left;
        font-size: 12px;
        margin-top: 24px;
        margin-bottom: 18px;
    }

    .main-content-choice_blocks > p:last-child {
        width: 100%;
        text-align: left;
        font-size: 12px;
        margin-bottom: 12px;
    }

    .main-content-doc {
        width: 100%;
        text-align: center;
        margin-top: 36px;
        margin-bottom:24px;
        font-size: 32px;

        .main-content-doc-img {
            width: 100%;   
        }
    }
}


@media (max-width: 768px) {
    .main-header {
        text-align: center;
    }

    .main-content-history-auditing {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 29px 32px 36px 32px;
    }

    .history-auditing-text {
        margin-left: 0px;
        margin-bottom: 0px;
        //display: none;
    }

    .main-content-choice-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        .main-content-choice_blocks {
            width: 100%;
        }
        
    }

    .main-content-choice > p:first-child {
        font-size: 32px;
        margin-bottom: 37px;
    }

    .main-content-choice_blocks > p {
        width: 100%;
    }

    .main-content-choice_blocks > p:last-child {
        width: 100%;
        text-align: left;
        font-size: 20px;
        margin-bottom: 12px;
    }

    .main-content-doc {
        width: 100%;
        text-align: center;
        margin-top: 24px;
        margin-bottom: 16px;
        font-size: 24px;

        .main-content-doc-img {
            width: 100%;   
        }
    }

    .main-content-choice-image {
        display: none;
    }
}

@media (max-width: 1024px) {
    .main-header > p:first-child {
        text-align: center;
    }
}


