.main-team {
    width: 100%;
    display: flex;
    margin-top: 140px;
}

.container-main {
    max-width: 1366px;
    width: 100%;
    margin: 35px auto;
}

.team-wrap--one {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction:column ;
    margin-right: auto;
    padding-right: 84px;
    padding-left: 5px;
    margin-bottom: auto;
}

.team-img {
    content: url(../../image/portret0.png);
    width: 327px;
    height: 335px;
    margin-bottom: 54px;
    margin-top: 24px;
}

.team-img1 {
    content: url(../../image/anna.png);
    width: 327px;
    height: 335px;
    margin-bottom: 54px;
    margin-top: 24px;
}

.team-img2 {
    content: url(../../image/victor.png);
    width: 327px;
    height: 335px;
    margin-bottom: 54px;
    margin-top: 24px;
}

.wrap-team--reverse,
.wrap-team {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin: auto;
    margin-bottom: 45px;
    overflow: hidden;
    position: relative;
}

.team-wrap--two {
    width: 679px;
    font-size: 24px;
    font-family: 'Open Sans', Regular;
    color: black;
    
    margin-bottom: auto;
}

.team-text_post {
    font-family: 'Merriweather', Bold;
    font-weight: bold;
    font-size: 24px;
    color: black;
    margin-bottom: 23px;
}

.team-text_name {
    text-align: center;
    font-family: 'Merriweather', Bold;
    font-weight: bold;
    font-size: 36px;
    color: black;
}


.line {
    max-width: 1106px;
    width: 100%;
    margin: auto;
    border: 1.5px solid #BDBBBB;
    margin-bottom: 97px;
}

.wrap-team--reverse {
    flex-direction: row-reverse;
}

.margin-img { 
    margin-right: 0;
    margin-left: auto;
    padding-left: 84px;
    padding-right: 0;
}

.team-wrap--two::before {
   animation-iteration-count: 0; 
}

.team-wrap--two::after {
    animation-iteration-count: 0;
    display: block;
}

.none {
    display: none;
}
