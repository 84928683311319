@mixin font-Merriweather-bold {
    font-family: 'Merriweather', serif;
    font-weight: 700;
}

@mixin font-Open_sans-regular {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
}

.anhor {
    position: absolute;
    top: -112px;
}

.service {
    width: 100%;
    min-height: 100vh;
    margin-top: 140px;
}

.service-conteiner {
    min-height: 100vh;
    padding-top: 140px;
    width: 100%;
}

.service-wrapper {
    display: inline-flex;
    width: 100%;
}

    .service-interior-wrapper {
        max-width: 1366px;
        margin: 0 auto;
        min-height: 100vh;
        padding-top: 70px; 
        padding-top: 73px;
        padding-right: 171px;
        padding-left: 89px;

    
    .image-plus {
        display: flex;
        height: 24px;
        width: 25px;
        transition: 0.25s linear;
        background: url('../../image/plus.png') no-repeat;
        background-size: contain;
        margin-right: 40px;
        cursor: pointer;
    }
    .image-plus-active {
        transition: 0.25s linear;
        transform: rotate(45deg);
        margin-right: 40px;
    }

    .feature-title-span {
        margin-bottom: 20px;
    }

    .feature-title {
        @include font-Merriweather-bold;
        font-size: 24px;
        text-align: left;
        color: #000000;
        opacity: 1;
        margin-bottom: 20px;
    }

    .feature-info {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .features-list{
        display: block;
        @include font-Open_sans-regular;
        text-align: left;
        font-size: 20px;
        color: #000000;
        opacity: 1;
        line-height: 1.4;
        margin-bottom: 70px;
        list-style-type: none;
        border-bottom: 1px solid #707070;
        width: 100%;
    }

    .last {
        border-bottom: none !important;
        margin-bottom: 0 !important;
    }

    .features-list {
        li {
            &:last-child  {
               margin-bottom: 67px; 
            }
            &:nth-child(7) {
                font-weight: 700;
            }
        }
    } 
    
    .features-list-hidden {
        display: none;
    }

    .features-description-list {
        display: none;
    }
    .features-description-list-visible {
        display: block;
        @include font-Open_sans-regular;
        text-align: left;
        font-size: 20px;
        color: #000000;
        opacity: 1;
        margin-left: 67.93px;
        margin-bottom: 35px;
    }
    .features-description-list-visible > li:first-child {
        list-style-type: none;
        margin-left: -20px;
}
    
    .features-description-list-visible > li:last-child {
        margin-top: 28px;
        list-style-type: none;
        margin-left: -20px;
}
    
}

@media (max-width: 960px) {
    .service-interior-wrapper {
        padding-top: 35px;
        padding-right: 20.5px;
        padding-bottom: 19px;
        padding-left: 8px;
    }

    .features-list > li:last-child {
        margin-bottom: 11px !important;
    }

    .features-list{
        margin-bottom: 19.6px !important;
    }

    .features-description-list-visible {
        margin-bottom: 19px !important;
    }

    .feature-title {
        font-size: 16px !important;
    }

    .features-list  {
        font-size: 14px !important;
    }

    .features-description-list-visible {
        font-size: 14px !important;
    }
}
