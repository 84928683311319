@import './../node_modules/antd/dist/antd.css';
@import './fonts.scss';
@import './../node_modules/animate.css/animate.css';
@import './../node_modules/animate.css/animate.min.css';
@import '../src/assets/themes/screens/960px.scss';
@import '../src/assets/themes/screens/960-Contact.scss';

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Open Sans', Regular;
  outline: none;
}

body {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  background-color: #fff;
}

#root {
    scroll-behavior: smooth;
    height: 100vh;
    width: 100%;
    margin: 0 auto;
    display: block;
    position: relative;
    box-sizing: border-box;
    background-color: #fff;
}

p {
  margin-bottom: 0;
}