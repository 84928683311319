@media (max-width: 960px) {
    .main-container {
        flex-direction: column-reverse;
        
    }
    .main-contact {
        display: block;
    }

    .container-google {
        padding-right: 190px;
        margin-top: 23px;
        margin-bottom: 20px;
        margin-right: 0;
    }

    .wrap-contact {
        margin-top: 23px;
    }

    iframe {
        width: 230px;
        height: 230px;
        margin-left: 40px;
        margin-right: 50px;
    }

    .wrap-text_h1 {
        font-size: 16px;
    }

    .wrap-text_p {
        font-size: 14px;
    }

    .margin38 {
        margin-bottom: 23px;
    }
    .margin27 {
        margin-bottom: 23px;
    }
    .margin31 {
        margin-bottom: 23px;
    }

    .margin34 {
        margin-bottom: 23px;
    }
}

@media (max-width: 500px) {

.wrap-contact {
    margin-left: 40px;
    margin-right: 50px;
}

}