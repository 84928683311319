@mixin font-Merriweather-regular {
    font-family: 'Merriweather', serif;
    font-weight: 400;
}

@mixin font-Merriweather-bold {
    font-family: 'Merriweather', serif;
    font-weight: 700;
}

 
.footer {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-top: 1px solid #BDBBBB;
    height: 75px;
}

.footer-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1366px;
    width: 100%;
    margin-right: 40px;
    margin-left: 80px;
}

.footer-contact-info > span {
    @include font-Merriweather-regular;
    font-size: 20px;
    color: #BDBBBB;
}

.footer-mobile > span {
        @include font-Merriweather-regular;
        font-size: 20px;
        color: #BDBBBB;
        
    }

@media (max-width: 960px) {
    
    .footer-contact-info {
        display: none;
    }
    .footer-wrapper {
        justify-content: center;
    }
    }

@media (max-width: 500px) {
    
    .footer {
        height: 27px;
        justify-content: center;
        display: flex;
        align-items: center;
    }

    .footer-mobile{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .footer-mobile > span {
        font-size: 12px;
    }

}